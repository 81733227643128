import React, {useEffect, useRef, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import CommentSection from '../components/CommentSection';
import api from '../api/axiosInstance';
import { Post } from '../models/types';

const PostContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(245, 102, 7, 0.3);  // Orange shadow
`;

const Heading = styled.h2`
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
`;

const Description = styled.p`
    font-size: 0.9em;
    margin-bottom: 20px;
    white-space: pre-wrap;
    text-align: left;
    width: 100%;
`;

const Media = styled.div`
    margin-top: 10px;
    width: 100%;
    img, video, audio {
        width: 100%;
        height: auto;
        border-radius: 5px;
    }
`;

const PostDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [post, setPost] = useState<Post | null>(null);
    const navigate = useNavigate();
    const commentsRef = useRef<HTMLDivElement>(null); // Reference to the comments section


    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await api.get(`/posts/${id}`);
                const fetchedPost: Post = response.data;

                if (fetchedPost.type.startsWith('video/')) {
                    const videoResponse = await api.get(`/posts/file/${fetchedPost.id}`, { responseType: 'arraybuffer' });
                    const videoBlob = new Blob([videoResponse.data], { type: videoResponse.headers['content-type'] });
                    const videoUrl = URL.createObjectURL(videoBlob);
                    setPost({
                        ...fetchedPost,
                        mediaUrl: videoUrl,
                    });
                } else if (fetchedPost.type.startsWith('audio/')) {
                    const audioResponse = await api.get(`/posts/file/${fetchedPost.id}`, { responseType: 'arraybuffer' });
                    const audioBlob = new Blob([audioResponse.data], { type: audioResponse.headers['content-type'] });
                    const audioUrl = URL.createObjectURL(audioBlob);
                    setPost({
                        ...fetchedPost,
                        mediaUrl: audioUrl,
                    });
                } else if (fetchedPost.type.startsWith('image/')) {
                    const imageResponse = await api.get(`/posts/file/${fetchedPost.id}`, { responseType: 'arraybuffer' });
                    const imageBlob = new Blob([imageResponse.data], { type: imageResponse.headers['content-type'] });
                    const imageUrl = URL.createObjectURL(imageBlob);
                    setPost({
                        ...fetchedPost,
                        mediaUrl: imageUrl,
                    });
                } else {
                    setPost(fetchedPost);
                }
            } catch (error) {
                console.error('Failed to fetch post details', error);
                navigate('/'); // Redirect to home if the post is not found
            }
        };

        fetchPost();
    }, [id, navigate]);

    // Scroll to the comments section when the post is loaded
    useEffect(() => {
        if (post && commentsRef.current) {
            commentsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [post]);

    if (!post) {
        return <div>Načítavanie...</div>;
    }


    return (
        <PostContainer>
            <Heading>{post.heading}</Heading>
            <Description>{post.description}</Description>
            <Media>
                {post.type.startsWith('video/') ? (
                    <ReactPlayer url={post.mediaUrl} controls playing width="100%" />
                ) : post.type.startsWith('audio/') ? (
                    <AudioPlayer src={post.mediaUrl} style={{ width: '100%' }} />
                ) : post.type.startsWith('image/') ? (
                    <img src={post.mediaUrl} alt="Post media" />
                ) : (
                    <p>No media available.</p>
                )}
            </Media>
            <CommentSection autoLoad={true} postId={post.id} commentsCount={post.commentsCount ?? 0} />
        </PostContainer>
    );
};

export default PostDetail;
