import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './styles.css';
import { Provider } from 'react-redux';
import { store } from './store/store';

const rootElement = document.getElementById('root');

if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
            <Provider store={store}>
                <App />
            </Provider>
    );
}
