import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../reducers/authSlice';
import api from "../api/axiosInstance";
import { IoArrowBack } from "react-icons/io5";

interface LoginModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onLogin: (data: any) => void;
}

const GradientContainer = styled.div`
    background: linear-gradient(135deg, #f56607, #000);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;            /* Full width of modal */
    max-width: 500px;        /* Max-width for larger screens */
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 40px;          /* Adjust padding as needed */
    padding-left: 40px;          /* Adjust padding as needed */
    border-radius: 10px;     /* Keep the border-radius */
    margin: 0 auto;          /* Center the container */
`;


const BackButton = styled.button`
    position: absolute;
    top: 20px;
    left: 20px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    z-index: 1;
`;

const Heading = styled.h2`
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    text-align: center;
`;

const FormContainer = styled.div`
    width: 100%;
    margin: 0 auto;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Input = styled.input`
    margin: 10px 0;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    border-radius: 10px;
    border: none;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    font-size: 16px;
    &::placeholder {
        color: #ddd;
    }
`;

const Button = styled.button`
    margin: 10px 0;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 5px rgba(245, 102, 7, 0.5);

    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
`;

const ErrorMessage = styled.p`
    color: red;
    text-align: center;
`;

const customStyles = {
    content: {
        width: '100%',          // Takes full width of its container
        maxWidth: '500px',       // Set a max-width for the modal
        height: 'auto',          // Adjust height based on content
        margin: 'auto',          // Center the modal in the container
        padding: '0',
        borderRadius: '10px',    // Keep the rounded corners as per the design
        border: 'none',
        background: 'none',      // Keep content background transparent
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.85)',  // Semi-transparent overlay
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0',
    }
};


const Label = styled.label`
    margin-bottom: 5px;
    font-weight: bold;
    color: white;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
`;

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onRequestClose, onLogin }) => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await api.post('/v1/auth/authenticate', { email, password });
            const { accessToken, refreshToken, user } = response.data;
            dispatch(loginSuccess({ accessToken, refreshToken, user }));
            onLogin(response.data);
            onRequestClose();
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                setError("Zadané meno alebo heslo je nesprávne");
            } else {
                setError("Prihlásenie zlyhalo. Skúste to znova.");
            }
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="custom-modal-content"
            style={customStyles}
            ariaHideApp={false}
        >
            <GradientContainer>
                <Heading>Vitajte späť</Heading>
                <FormContainer>
                    <Form onSubmit={handleSubmit}>
                        <Label>Email</Label>
                        <Input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Label>Heslo</Label>
                        <Input
                            type="password"
                            placeholder="Heslo"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                        <Button type="submit">Prihlásiť sa</Button>
                    </Form>
                </FormContainer>
            </GradientContainer>
        </Modal>
    );
};

export default LoginModal;
