import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';
import CookiesModal from './CookiesModal'; // Assuming you have a CookiesModal component

const StyledLink = styled.a`
    color: #f56607;  /* Orange color for the link */
    text-decoration: underline;

    &:hover {
        color: #ff7f00;  /* Slightly lighter orange on hover */
    }
`;

const CookieBanner: React.FC = () => {
    const [isCookiesModalOpen, setIsCookiesModalOpen] = useState(false);

    const openCookiesModal = () => {
        setIsCookiesModalOpen(true);
    };

    const closeCookiesModal = () => {
        setIsCookiesModalOpen(false);
    };

    return (
        <>
            <CookieConsent
                location="bottom"
                buttonText="Prijať"
                cookieName="userCookieConsent"
                style={{ background: "#2B373B" }}
                buttonStyle={{
                    color: "#fff",
                    fontSize: "13px",
                    backgroundColor: "#f56607",
                    borderRadius: "5px",
                    padding: "10px 20px",
                }}
                expires={365}
                // Temporarily cast props to any to bypass type checking issues
                {...({} as any)}
            >
                Tento web používa cookies na zabezpečenie čo najlepšieho zážitku z našich stránok.{" "}
                <StyledLink href="#" onClick={openCookiesModal}>
                    Nastavenie cookies
                </StyledLink>
            </CookieConsent>

            {/* Cookie Settings Modal */}
            <CookiesModal isOpen={isCookiesModalOpen} onRequestClose={closeCookiesModal} />
        </>
    );
};

export default CookieBanner;
