import React from 'react';
import styled from 'styled-components';
import ModalComponent from './ModalComponent';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

interface CookiesModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
}

const CookiesContent = styled.div`
    p {
        margin-bottom: 10px;
        line-height: 1.5;
    }

    .description {
        font-size: 0.9em;
        color: #3ada5a;
        margin-right: 39px; /* Adjust margin to align with the switch */
        text-align: center;
        margin-bottom: 20px;
    }

    .switch-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
`;

const CookiesModal: React.FC<CookiesModalProps> = ({ isOpen, onRequestClose }) => {
    return (
        <ModalComponent isOpen={isOpen} onClose={onRequestClose} title="Cookie - Nastavenie">
            <CookiesContent>
                <p>Tu máte možnosť prispôsobiť súbory cookie podľa kategórií, v súlade s vlastnými preferenciami.</p>
                <div className="switch-container">
                    <FormControlLabel
                        control={<Switch checked={true} disabled />}
                        label={<strong>Technické – aby naše stránky mohli fungovať</strong>}
                        labelPlacement="start"
                    />
                </div>
                <p className="description">
                    Vždy aktívne
                </p>
                <p >
                    Tieto cookies sú nevyhnutné kvôli správnemu fungovaniu, bezpečnosti, riadnemu zobrazovaniu na počítači alebo na mobile, uchovávanie produktov v košíku, fungujúcemu vyplňovaniu či odosielaniu formulárov a podobne. Technické cookies nie je možné vypnúť, bez nich by naše stránky nefungovali správne.
                </p>
            </CookiesContent>
        </ModalComponent>
    );
};

export default CookiesModal;
