import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import api from "../api/axiosInstance";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import OnlyPremium from "./OnlyPremium";

export interface CommentNotificationDto {
    id: number;
    postName: string;
    comment: string;
    commenterName: string;
    createdAt: string;
    wasSeen: boolean;
    fkPost: number;
}

const NotificationContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
`;

const Heading = styled.h2`
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
`;

const NotificationItem = styled.div<{ wasSeen: boolean }>`
    background-color: ${({ wasSeen }) => (wasSeen ? '#f9f9f9' : '#fff')};
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 10px rgba(245, 102, 7, 0.15); // Orange shadow similar to React Native

    &:hover {
        background-color: #f0f0f0;
    }
`;

const NotificationDetails = styled.div`
    flex: 1;
    margin-right: 15px;
`;

const NotificationTitle = styled.h4`
    margin: 0;
    font-size: 1em;
    color: #333;
`;

const NotificationComment = styled.p`
    margin: 5px 0;
    color: #555;
`;

const NotificationDate = styled.span`
    font-size: 0.8em;
    color: #999;
`;

const LoadMoreButton = styled.button`
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #f56607;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #ff7f00;
    }
`;

const CommentNotification: React.FC = () => {
    const [notifications, setNotifications] = useState<CommentNotificationDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0); // Page counter
    const [hasMore, setHasMore] = useState(false); // Indicator if more notifications are available
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.auth.user);

    useEffect(() => {
        const fetchNotifications = async () => {
            setLoading(true);
            try {
                const response = await api.get(`/comment-notifications/user/${user?.id}`, {
                    params: {
                        page: page,
                        size: 5, // Load 5 notifications at a time
                    },
                });
                const newNotifications = response.data.content;
                setNotifications((prevNotifications) => [...prevNotifications, ...newNotifications]);
                setHasMore(newNotifications.length === 5); // If exactly 5 notifications are loaded, there might be more
            } catch (error) {
                console.error('Failed to fetch notifications', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNotifications();
    }, [page, user?.id]);

    const handleNavigateToPost = (postId: number) => {
        navigate(`/post/${postId}`);
    };

    const handleLoadMore = () => {
        setPage((prevPage) => prevPage + 1); // Load the next page
    };

    if (!user?.isPremium) {
        return <OnlyPremium />;
    }

    return (
        <NotificationContainer>
            <Heading>Upozornenia</Heading>
            {loading && notifications.length === 0 ? (
                <p>Načítavanie upozornení...</p>
            ) : (
                notifications.map((notification) => (
                    <NotificationItem
                        key={notification.id}
                        wasSeen={notification.wasSeen}
                        onClick={() => handleNavigateToPost(notification.fkPost)}
                    >
                        <NotificationDetails>
                            <NotificationTitle>
                                {notification.commenterName} komentoval príspevok {notification.postName}
                            </NotificationTitle>
                            <NotificationComment>{notification.comment}</NotificationComment>
                            <NotificationDate>
                                {new Date(notification.createdAt).toLocaleString('sk-SK', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </NotificationDate>
                        </NotificationDetails>
                    </NotificationItem>
                ))
            )}
            {hasMore && (
                <LoadMoreButton onClick={handleLoadMore}>
                    Načítať viac
                </LoadMoreButton>
            )}
        </NotificationContainer>
    );
};

export default CommentNotification;
