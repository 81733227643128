import React, { useState } from 'react';
import { Box, TextField, IconButton, Typography, Button } from '@mui/material';
import { PlayArrow as PlayIcon, Stop as StopIcon } from '@mui/icons-material';

interface ChatHeaderProps {
    isAdmin: boolean | null;
    activeSessionName: string | null;
    onCreateSession: (name: string) => void;
    onStopSession: () => void;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ isAdmin, activeSessionName, onCreateSession, onStopSession }) => {
    const [sessionName, setSessionName] = useState<string>('');

    const handleCreateSession = () => {
        if (sessionName.trim() !== '') {
            onCreateSession(sessionName);
            setSessionName('');
        }
    };

    return (
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #3A3B3C', backgroundColor: '#242526' }}>
            {isAdmin ? (
                activeSessionName ? (
                    <>
                        <Typography variant="h6" sx={{ color: '#E4E6EB' }}>
                            Aktívny Live Chat: {activeSessionName}
                        </Typography>
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<StopIcon />}
                            onClick={onStopSession}
                        >
                            Ukončiť live chat
                        </Button>
                    </>
                ) : (
                    <>
                        <TextField
                            variant="outlined"
                            label="Názov Live Chatu"
                            value={sessionName}
                            onChange={(e) => setSessionName(e.target.value)}
                            sx={{
                                backgroundColor: '#3A3B3C',
                                borderRadius: '4px',
                                color: '#E4E6EB',
                                input: { color: '#E4E6EB' },
                                label: { color: '#E4E6EB' },
                            }}
                        />
                        <IconButton color="primary" onClick={handleCreateSession}>
                            <PlayIcon />
                        </IconButton>
                    </>
                )
            ) : activeSessionName ? (
                <Typography variant="h6" sx={{ color: '#E4E6EB' }}>
                    Active Session: {activeSessionName}
                </Typography>
            ) : (
                <Typography variant="h6" sx={{ color: '#E4E6EB' }}>
                    Momentalne Chat nie je aktivny
                </Typography>
            )}
        </Box>
    );
};

export default ChatHeader;
