import React, { useState } from 'react';
import { Box, Typography, IconButton, Popover, Avatar } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material'; // Use the Delete Icon
import { styled } from '@mui/system';
import ChatBubbleAudioPlayer from "./ChatBubbleAudioPlayer";

const emojiMap: { [key: number]: string } = {
    1: '🔥',  // Fire
    2: '🍀',  // Clover
    3: '❤️️',  // Clapping Hands
    4: '⚠️',  // Heart
    5: '👍',  // Warning
    6: '👎',  // Thumbs Up
    7: '👏',  // Heart Hands
    8: '🫶',  // Thumbs Down
};

interface Reaction {
    emoji: string;
    count: number;
}

interface ChatBubbleProps {
    message: string;
    reactions: Reaction[];
    timestamp: string;
    sentDate: string;
    onReact: (emojiIndex: number) => void;
    audioId?: number | null; // Optional audio ID for voice messages
    onDelete: () => void; // Added onDelete prop for delete functionality
    isAdmin?: boolean; // Check if user is an admin
}

const MessageText = styled(Typography)({
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: '14px',
    color: '#E4E6EB',
    padding: '4px 5px',
    wordBreak: 'break-word',
});

const ChatBubble: React.FC<ChatBubbleProps> = ({ message, reactions, onReact, onDelete, timestamp, sentDate, audioId,     isAdmin,}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEmojiClick = (emojiIndex: number) => {
        onReact(emojiIndex);
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'emoji-popover' : undefined;

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
            <Avatar
                src="./g.png"
                alt="Logo"
                sx={{ width: 35, height: 35, marginRight: 2 }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minWidth: 0 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                    <Typography variant="body2" sx={{ color: '#E4E6EB', fontWeight: 'bold', marginRight: 1 }}>
                        Admin
                    </Typography>
                    <Typography variant="caption" sx={{ color: '#B0B3B8' }}>
                        {timestamp} {new Date(sentDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        maxWidth: '70%',
                        borderRadius: 4,
                        backgroundColor: '#3A3B3C',
                        color: '#E4E6EB',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                        wordBreak: 'break-word',
                        padding: '8px',
                        minWidth: 'fit-content',
                    }}
                >
                    {audioId ? (
                        <ChatBubbleAudioPlayer audioId={audioId} autoplay={false} />
                        ) : (
                        <MessageText variant="body2">{message}</MessageText>
                )}
                    <Box
                        sx={{
                            mt: 1,
                            display: 'flex',
                            gap: 0.5,
                            position: 'absolute',
                            bottom: +4,
                            left: 69,
                        }}
                    >
                        {reactions.map((reaction, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.3,
                                    backgroundColor: '#494A4B',
                                    borderRadius: '8px',
                                    padding: '2px 4px',
                                    border: '2px solid #18191A',
                                }}
                            >
                                <Typography variant="caption">
                                    {reaction.count > 1 && `${reaction.count}`}
                                </Typography>
                                <Typography variant="caption">{reaction.emoji}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>

            <IconButton
                aria-describedby={id}
                size="small"
                sx={{
                    color: '#E4E6EB',
                    ml: 1,
                    backgroundColor: '#494A4B',
                    '&:hover': {
                        backgroundColor: '#606162',
                    },
                    alignSelf: 'center',
                    padding: '5px',
                    zIndex: 1,
                    position: 'relative',
                }}
                onClick={handleClick}
            >
                <AddIcon fontSize="small" />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: { padding: '8px', backgroundColor: '#3A3B3C', color: '#E4E6EB' },
                }}
            >
                <Box sx={{ display: 'flex', gap: 1 }}>
                    {[1, 2, 3, 4, 5].map((num) => (
                        <IconButton
                            key={num}
                            onClick={() => handleEmojiClick(num)}
                            sx={{ fontSize: '14px', color: '#E4E6EB' }}
                        >
                            <Typography variant="body2">{emojiMap[num]}</Typography>
                        </IconButton>
                    ))}

                    {/* Conditionally render Delete button for admin users */}
                    {isAdmin && (
                        <IconButton
                            onClick={() => {
                                onDelete();
                                handleClose(); // Close popover after delete
                            }}
                            sx={{ fontSize: '14px', color: '#E4E6EB' }}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    )}
                </Box>
            </Popover>
        </Box>
    );
};

export default ChatBubble;
