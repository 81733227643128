import React, { useState, useEffect } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    TextField,
    Typography,
    Divider,
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import api from '../api/axiosInstance'; // Assuming you have an axios instance set up

interface User {
    id: number;
    name: string;
    avatarUrl: string;
}

const SelectUserPage: React.FC = () => {
    const [allUsers, setAllUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.get('/users'); // Assume you have an endpoint like this to get all users
                setAllUsers(response.data);
                setFilteredUsers(response.data);
            } catch (error) {
                console.error('Failed to fetch users', error);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        setFilteredUsers(
            allUsers.filter((user) =>
                user.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, allUsers]);

    const handleUserClick = (user: User) => {
        // Navigate back to the messages page with the selected user's information
        navigate('/messages', { state: { selectedUser: user } });
    };

    return (
        <Box sx={{ p: 2, maxWidth: 600, mx: 'auto' }}>
            <Typography variant="h4" align="center" gutterBottom>
                Select a User to Message
            </Typography>

            <TextField
                fullWidth
                variant="outlined"
                label="Search users"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 2 }}
            />

            <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
                <List>
                    {filteredUsers.map((user) => (
                        <ListItem
                            key={user.id}
                            button
                            onClick={() => handleUserClick(user)}
                        >
                            <ListItemAvatar>
                                <Avatar src={user.avatarUrl} alt={user.name} />
                            </ListItemAvatar>
                            <ListItemText primary={user.name} />
                            <Divider component="li" />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default SelectUserPage;
