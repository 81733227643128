import React, { useState } from 'react';
import styled from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';
import axios from "axios";
import api from "../api/axiosInstance";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    max-width: 400px;
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
`;

const BackButton = styled.button`
    align-self: flex-start;
    background-color: transparent;
    border: none;
    color: #f56607;
    cursor: pointer;
    font-size: 1.5em;

    &:hover {
        color: #f58802;
    }
`;

const Input = styled.input`
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ddd;
`;

const TextArea = styled.textarea`
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    height: 150px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ddd;
`;

const SubmitButton = styled.button`
    margin: 10px 0;
    padding: 10px 20px;
    background-color: #f56607;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #f58802;
    }
`;

interface NewsUploadSectionProps {
    onBack: () => void;
    onReload: () => void;  // Add this prop to trigger reload
}

const NewsUploadSection: React.FC<NewsUploadSectionProps> = ({ onBack, onReload }) => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const handleSubmit = async () => {
        const newsDto = {
            title,
            content,
        };

        try {
            const response = await api.post('/news', newsDto);
            onReload(); // Trigger reload of messages
            onBack(); // Go back to the previous view after submitting
        } catch (error) {
        }
    };

    return (
        <Container>
            <UploadContainer>
                <BackButton onClick={onBack}>
                    <FaArrowLeft />
                </BackButton>
                <h2>Pridať správu</h2>
                <Input
                    type="text"
                    placeholder="Zadajte názov"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextArea
                    placeholder="Zadajte obsah"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                />
                <SubmitButton onClick={handleSubmit}>Pridať</SubmitButton>
            </UploadContainer>
        </Container>
    );
};

export default NewsUploadSection;
