import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import api from "../api/axiosInstance";

// Define the DTO interface
interface HeaderDTO {
    id: number;
    headerText: string;
    description: string;
}

// Styled components
const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    width: 100%;
    max-width: 600px;
`;

const Logo = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 20px;
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;

const CommunityName = styled.h1`
    font-size: 2em;
    margin-top: 10px;
`;

const Description = styled.p`
    font-size: 1em;
    margin-top: 11px;
    padding: 0 20px;
    width: 100%;
    max-width: 350px;
`;

const Stats = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;

    span {
        font-size: 0.9em;
    }
`;

const LoadingSpinner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
`;

// ProfileHeader component
const ProfileHeader = () => {
    const [headerData, setHeaderData] = useState<HeaderDTO | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchHeaderData = async () => {
            try {
                const response = await api.get<HeaderDTO>('/headers/1'); // Replace with your API endpoint
                setHeaderData(response.data);
            } catch (err) {
                console.error('Error fetching header data:', err);
                setError('Error fetching data');
            } finally {
                setLoading(false);
            }
        };

        fetchHeaderData();
    }, []);

    if (loading) {
        return (
            <LoadingSpinner>
                <p>Loading...</p>
            </LoadingSpinner>
        );
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <Header>
            <Logo src="./g.png" alt="Community Logo" />
            <Info>
                <CommunityName>{headerData?.headerText}</CommunityName>
                <Description>{headerData?.description}</Description>
                <Stats>
                    {/* You can add any stats here if needed */}
                </Stats>
            </Info>
        </Header>
    );
};

export default ProfileHeader;
