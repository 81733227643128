import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

// Set the app element for accessibility
Modal.setAppElement('#root');

interface ModalComponentProps {
    isOpen: boolean;
    onClose: () => void; // This is the prop we're using for the close functionality
    title?: string;
    children: React.ReactNode; // This will accept any valid React nodes as children
}

// Styling for the Modal content
const ModalContent = styled.div`
    position: relative;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 0 auto;
`;

// Styling for the title
const ModalTitle = styled.h2`
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
`;

// Styling for the close button
const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5em;
    color: #333;
    cursor: pointer;

    &:hover {
        color: #f56607;
    }
`;

const ModalComponent: React.FC<ModalComponentProps> = ({ isOpen, onClose, title, children }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose} // Use onRequestClose to tie into React Modal's close handling
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                content: {
                    inset: 'auto',
                    padding: 0,
                    border: 'none',
                    borderRadius: '10px',
                    maxWidth: '500px',
                    width: '90%',
                    margin: 'auto',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                },
            }}
        >
            <ModalContent>
                {title && <ModalTitle>{title}</ModalTitle>}
                <CloseButton onClick={onClose}>&times;</CloseButton>
                {children}
            </ModalContent>
        </Modal>
    );
};

export default ModalComponent;
