// src/api/axiosInstance.ts
import axios from 'axios';
import config from '../config';
import { store } from '../store/store';
import { logout } from '../reducers/authSlice';

// Create an Axios instance
const api = axios.create({
    baseURL: config.API_URL,
});

// Add a request interceptor
api.interceptors.request.use(
    (requestConfig) => {
        const state = store.getState(); // Access the Redux state directly
        const token = state.auth.accessToken;

        // Exclude login and register endpoints from having the Authorization header
        if (token && !requestConfig.url?.includes('/login') && !requestConfig.url?.includes('/register')) {
            requestConfig.headers.Authorization = `Bearer ${token}`;
        }
        return requestConfig;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle errors like token expiration
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 403) {
            // Handle token expiration or unauthorized errors
            store.dispatch(logout()); // Log the user out
        }
        return Promise.reject(error);
    }
);

export default api;
