import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Hls from 'hls.js';
import api from "../api/axiosInstance";

interface VideoPlayerProps {
    videoId: number;
    autoplay?: boolean;
    onReady?: (player: HTMLVideoElement) => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoId, autoplay = false, onReady }) => {
    const [videoSrc, setVideoSrc] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        const fetchAndModifyPlaylist = async () => {
            try {
                const baseURL = api.defaults.baseURL;

                const response = await api.get(`/posts/${videoId}/playlist.m3u8`, {
                    responseType: 'text',
                });

                let playlist = response.data;

                // Modify the playlist URLs if needed
                if (baseURL) {
                    playlist = playlist.replace(/output(\d+).ts/g, (match: any, p1: any) => {
                        return `${baseURL}/posts/${videoId}/output${p1}.ts`;
                    });
                }

                const blob = new Blob([playlist], { type: 'application/vnd.apple.mpegurl' });
                const url = URL.createObjectURL(blob);
                setVideoSrc(url);
            } catch (error) {
                console.error('Error fetching video:', error);
                setError('Could not load video');
            }
        };

        fetchAndModifyPlaylist();

        return () => {
            if (videoSrc) {
                URL.revokeObjectURL(videoSrc);
            }
        };
    }, [videoId]);

    useEffect(() => {
        if (videoRef.current && videoSrc) {
            if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(videoSrc);
                hls.attachMedia(videoRef.current);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    if (autoplay) {
                        videoRef.current?.play().catch((err) => {
                            console.error('Autoplay failed:', err);
                        });
                    }
                    if (onReady) {
                        onReady(videoRef.current!);
                    }
                });
            } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                videoRef.current.src = videoSrc;
                if (autoplay) {
                    videoRef.current.play().catch((err) => {
                        console.error('Autoplay failed:', err);
                    });
                }
                if (onReady) {
                    onReady(videoRef.current);
                }
            }
        }
    }, [videoSrc, autoplay, onReady]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <video ref={videoRef} controls style={{ width: '100%' }} className="video-js vjs-big-play-centered" />
        </div>
    );
};

export default VideoPlayer;
