import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import PaymentStatus from './components/PaymentStatus';
import Home from './pages/Home';
import Account from './pages/Account';
import ProtectedRoute from './components/ProtectedRoute';
import News from './components/News';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import styled from 'styled-components';
import './App.css';
import SelectUserPage from "./components/SelectUserPage";
import EditProfile from "./components/EditProfile";
import ChangePassword from "./components/ChangePassword";
import ChangeBillingDetails from "./components/ChangeBillingDetails";
import ProfileSettings from "./components/ProfileSettings";
import OrderHistory from "./components/OrderHistory";
import CommentNotification from "./components/CommentNotifications";
import PostDetail from "./components/PostDetail";
import CookieBanner from "./components/CustomCookieConsent";
import Messages from "./components/Messages";
import FAQ from "./components/Faq";

const stripePromise = loadStripe('pk_live_51PexFn2LxS9rV2S4jEp30qRFF8DK5Crr6KxDD3NsnEgLrl12PZQ7hCENsCKyLf7O6TIuSMfNbnjfVHeGk0Osh2EW00Rf4hdjgi');

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f8f9fa;  // Light background

`;

const App: React.FC = () => {
    return (
        <Router>
            <AppContainer>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/faq" element={<FAQ />} />

                    <Route element={<ProtectedRoute />}>
                        <Route path="/account" element={<Account />} />
                        <Route path="/payment-status" element={
                            <Elements stripe={stripePromise}>
                                <PaymentStatus />
                            </Elements>
                        } />
                        <Route path="/news" element={<News />} />
                        <Route path="/messages/select-user" element={<SelectUserPage />} />
                        <Route path="/edit-profile" element={<EditProfile/>} />
                        <Route path="/change-password" element={< ChangePassword/>} />
                        <Route path="/change-billing-details" element={<ChangeBillingDetails/>} />
                        <Route path="/profile-settings" element={<ProfileSettings/>} />
                        <Route path="/order-history" element={<OrderHistory />} />
                        <Route path="/comment-notification" element={<CommentNotification />} />
                        <Route path="/post/:id" element={<PostDetail />} />
                        <Route path="/messages/" element={<Messages />} />

                    </Route>
                </Routes>
                <CookieBanner />
            </AppContainer>
        </Router>
    );
};

export default App;
