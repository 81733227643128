import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    padding: 20px;
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const BackButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
`;

const Heading = styled.h2`
    margin: 0;
    font-size: 24px;
`;

const FullWidthButton = styled.button`
    width: 100%;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: #fff;  // White background for regular buttons
    color: #000;
    margin-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    &:hover {
        background-color: #f0f0f0;  // Subtle hover effect
    }
`;

const ProfileSettings: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Header>
                <BackButton onClick={() => navigate(-1)}>&larr;</BackButton>
                <Heading>Nastavenia profilu</Heading>
            </Header>
            <FullWidthButton onClick={() => navigate('/edit-profile')}>Upraviť profil</FullWidthButton>
            <FullWidthButton onClick={() => navigate('/change-password')}>Zmeniť heslo</FullWidthButton>
            <FullWidthButton onClick={() => navigate('/change-billing-details')}>Zmeniť fakturačné údaje</FullWidthButton>
        </Container>
    );
};

export default ProfileSettings;
